var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$screen.width >= 992
        ? _c(
            "b-row",
            { staticClass: "form" },
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: this.FormMSG(2, "Type to Search"),
                            },
                            on: { change: _vm.handleChangeFilter },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(
                                "b-input-group-text",
                                { staticClass: "btn-search" },
                                [
                                  _vm.filter.length === 0
                                    ? _c("Search", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                      })
                                    : _c("X", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChangeFilter("")
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _vm.$screen.width < 992
                ? _c("CardListBuilder", {
                    attrs: {
                      items: _vm.users,
                      fields: _vm.userRequestFields,
                      "omitted-fields": ["actions"],
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "actions",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn bg-transparent border-0",
                                  attrs: { variant: "primary", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickEditUserRequest(
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.EDIT.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn bg-transparent border-0",
                                  attrs: { variant: "none", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickDeleteUserRequest(
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.TRASH.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2228467211
                    ),
                  })
                : _vm._e(),
              _vm.$screen.width >= 992
                ? _c("b-table", {
                    attrs: {
                      hover: "",
                      responsive: "",
                      selectable: "",
                      "selected-variant": _vm.selectedColor,
                      "select-mode": _vm.selectMode,
                      items: _vm.users,
                      "sort-by": "name",
                      filter: _vm.filter,
                      fields: _vm.userRequestFields,
                      "sticky-header": "300px",
                      "head-variant": _vm.hv,
                      bordered: "",
                      small: "",
                      "show-empty": "",
                      "empty-text": _vm.FormMSG(25, "No invitation found"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(actions)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { variant: "none", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickEditUserRequest(
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.EDIT.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "none", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickDeleteUserRequest(
                                        data.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.TRASH.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      159972010
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "hide-header-close": "",
            title: _vm.FormMSG(354, "Update invitation user"),
            size: "xxl",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "cancel-variant": "light",
            "modal-class": "mui-animation",
            fade: false,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-end align-items-center",
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "4", md: "4", lg: "2", xl: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "md",
                                variant: "light",
                                block: "",
                              },
                              on: { click: _vm.okAfterUserEdit },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(351, "Close")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "8", md: "8", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "md",
                                variant: "primary",
                                disabled: _vm.watingAddProject,
                                block: "",
                              },
                              on: { click: _vm.dispatchEvent },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c("b-spinner", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.watingAddProject,
                                        expression: "watingAddProject",
                                      },
                                    ],
                                    attrs: { small: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: { "margin-top": "1px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(3, "Update invitation")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showUserModal,
            callback: function ($$v) {
              _vm.showUserModal = $$v
            },
            expression: "showUserModal",
          },
        },
        [
          _c("user-form", {
            ref: "update-invitation",
            attrs: {
              "display-add-btn": false,
              projectId: _vm.projectId,
              userEntryLevel: _vm.userEntryLevel,
              "edit-data": _vm.editData,
            },
            on: {
              closeForm: _vm.handleCloseForm,
              "user-form:success": _vm.handleUserFormSuccess,
              "user-form:watingAddUpdProject": _vm.handleWatingAddUpdProject,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }