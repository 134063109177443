var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "b-button",
        {
          staticClass: "px-5",
          attrs: { variant: "success" },
          on: { click: _vm.inviteNewMember },
        },
        [
          _vm._v(
            "\n\t\t" + _vm._s(_vm.FormMSG(1, "Invite new crew member")) + "\n\t"
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "hide-header-close": "",
            title: _vm.FormMSG(11, "Invite user"),
            size: "xl",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "cancel-variant": "light",
            "modal-class": "mui-animation",
            fade: false,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-end align-items-center",
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "4", md: "4", lg: "2", xl: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "md",
                                variant: "light",
                                block: "",
                              },
                              on: { click: _vm.okAfterUserEdit },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(351, "Close")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "8", md: "8", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "md",
                                variant: "primary",
                                disabled: _vm.watingAddProject,
                                block: "",
                              },
                              on: { click: _vm.dispatchEvent },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c("b-spinner", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.watingAddProject,
                                        expression: "watingAddProject",
                                      },
                                    ],
                                    attrs: { small: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: { "margin-top": "1px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(2, "Invite crew member")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showUserModal,
            callback: function ($$v) {
              _vm.showUserModal = $$v
            },
            expression: "showUserModal",
          },
        },
        [
          _c("user-form", {
            ref: "invite-member",
            attrs: {
              "display-add-btn": false,
              projectId: _vm.projectId,
              "edit-data": null,
            },
            on: {
              closeForm: _vm.handleCloseForm,
              "user-form:success": _vm.handleUserFormSuccess,
              "user-form:watingAddUpdProject": _vm.handleWatingAddUpdProject,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }